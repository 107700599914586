<template>
  <q-item multiline class="vehicle" @click.native="goToDetail">
    <q-item-section side class="img-container">
      <m-image
        :src="vehicle.vehicle.images[0]"
        :fallback="fallback"
        :style="'max-width: 64px; max-height: 64px'"
      />
    </q-item-section>
    <q-item-section>
      <q-item-label label lines="1">
        {{ vehicle.title }}
      </q-item-label>
      <q-item-label caption lines="3">
        {{ vehicle.description }}
      </q-item-label>
    </q-item-section>
    <q-item-section side>
      <q-btn :label="priceText" outline class="btn-price" />
    </q-item-section>
  </q-item>
</template>

<script>
import { priceText as priceTextUtil } from 'utils/onwardTravel'
import { MImage } from 'components/'

export default {
  components: { MImage },
  props: ['vehicle'],
  data () {
    return {
      fallback: require('assets/blank-vehicle-hire.png')
    }
  },
  computed: {
    priceText () {
      return priceTextUtil(this.vehicle)
    }
  },
  methods: {
    goToDetail () {
      this.$store.dispatch('ondemand/stashProp', { path: 'selected', value: this.vehicle })
      this.$router.push({ name: 'ondemand-ride-hailing-vehicle' })
    }
  }
}

</script>

<style lang="stylus" scoped>
.detail
  font-size 14px

.vehicle
  padding 10px 8px
  transition transform .2s ease-in
  cursor pointer
  display grid
  grid-template-columns 4.5em auto 4.5em
  & >>> .q-item__section
    padding 0

.img-container
  width 4em
  height: 64px
  display: flex;
  align-items: center;
  justify-content: center;

.btn-price
  color #4361EE!important
  border-color #4361EE!important

.vehicle:hover .btn-price
  background #4361EE!important
  border-color #4361EE!important
  color white!important
</style>
