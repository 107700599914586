<template>
  <div class="map-form-layout">
    <m-gmaps ref="map" />
    <m-bottom-sheet id="map-form" ref="sheet">
      <router-view />
    </m-bottom-sheet>
  </div>
</template>

<script>
import { MGmaps } from 'components/'
// Manually import so we can access it straight away
import MBottomSheet from 'components/MBottomSheet'

export default {
  components: { MBottomSheet, MGmaps },
  activated () {
    this.$nextTick(() => {
      const sheet = document.getElementById('map-form')
      sheet.scrollIntoView({ behavior: 'smooth' })
    })
  },
  created () {
    this.$nextTick(() => {
      const sheet = document.getElementById('map-form')
      sheet.scrollIntoView({ behavior: 'smooth' })
    })
  }
}
</script>

<style lang="stylus" scoped>
.map-form-layout
  position relative

.vue-map-container
  position fixed
  width 100%
  height calc(100vh - 64px)
  z-index 1

#map-form
  min-height 300px
  width 30vw
  left 35%
  right 35%
  @media (max-width 768px)
    width 100vw
    left 0
    right 0
</style>
