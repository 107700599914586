<template>
  <div
    :class="{
      'bottom-sheet': bottomsheet, 'fixed-sheet': !bottomsheet
    }"
  >
    <slot name="background" />
    <div ref="sheet" class="drawer bg-white">
      <div v-show="bottomsheet" class="dragbar" />
      <slot name="default" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { hasRole } from 'utils/authentication'
import { scroll } from 'quasar'
export default {
  name: 'MBottomSheet',
  data () {
    return {
      bottomsheet: false
    }
  },
  computed: {
    ...mapGetters({
      roles: 'roles'
    })
  },
  watch: {
    '$route': {
      deep: true,
      immediate: true,
      handler (route, old) {
        if (route?.name === old?.name) return
        this.bottomsheet = !!this.$route.meta?.layout?.bottomsheet
        // this.scrollToBottom()
      }
    }
  },
  beforeMount () {
    if (!hasRole('guest')) this.$store.dispatch('addresses/getSavedLocations')
  },
  methods: {
    scrollTo (offset, speed = 150) {
      scroll.setScrollPosition(document.documentElement, offset, speed)
    },
    scrollToBottom () {
      this.scrollTo(0)
    },
    scrollToTop () {
      this.scrollTo(this.$el.getBoundingClientRect().height)
    },
    scrollToLow () {
      this.scrollTo(window.innerHeight / 4)
    },
    scrollToHigh () {
      this.scrollTo(window.innerHeight - 360)
    },
    scrollToFull () {
      this.scrollTo(window.innerHeight - 220)
    }
  }
}
</script>
<style lang="stylus" scoped>
.bottom-sheet,
.fixed-sheet
  position relative
.fixed-sheet .drawer
    width 100%
    min-height 100%
    padding-bottom 0
.bottom-sheet
    top calc(100vh - 17em)
    padding-top 32px
    z-index 1
  .drawer
    border-radius 16px 16px 0
    padding-bottom calc(64px + env(safe-area-inset-bottom))
  .dragbar
    position relative
    z-index 1
    &:after
      content ''
      position absolute
      top 8px
      left calc(50vw - 4vw)
      width 8vw
      height 4px
      border-radius 4px
</style>
