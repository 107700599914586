<template>
  <div class="slide">
    <q-scroll-area class="scrolling-content">
      <q-list no-border class="vehicles">
        <vehicle-card
          v-for="v in vehicleGroup.options"
          :key="v.token"
          :vehicle="v"
        />
      </q-list>
    </q-scroll-area>
  </div>
</template>

<script>
import vehicleCard from './vehicle-card'
export default {
  components: { vehicleCard },
  props: ['vehicleGroup']
}
</script>

<style lang="stylus" scoped>
.slide
  padding 0
  overflow hidden

.name
  padding 10px
  width 100%
  display block
  text-align center
  font-size 20px

.carousel
  width 100%

.scrolling-content
  height 256px

</style>
