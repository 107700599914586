<template>
  <div v-if="selectedVehicle" class="vehicle">
    <q-card class="bg-white" flat>
      <q-card-section class="vehicle-card-title row">
        <div class="col-xs-12">
          <div class="vehicle-detail">
            <q-btn v-if="$q.platform.is.desktop" icon="fal fa-arrow-left" round flat dense @click="$router.go(-1)" />{{ selectedVehicle.title }}
            <span>{{ priceText }}</span>
          </div>
          <p class="description">
            {{ selectedVehicle.description }}
          </p>
        </div>
      </q-card-section>
      <q-card-section class="row">
        <div class="col-xs-4">
          <m-image :src="selectedVehicle.vehicle.images[0]" :fallback="fallback" class="img" />
        </div>
        <div class="vehicle-info col-xs-4">
          <q-icon class="icon" name="people" />
          <p v-if="selectedVehicle.vehicle.passenger_capacity">
            {{ $t('num.seats', { num: selectedVehicle.vehicle.passenger_capacity}) }}
          </p>
          <p v-else>
            {{ $t('abbreviation.not_applicable') }}
          </p>
        </div>
        <div class="vehicle-info col-xs-4">
          <q-icon class="icon" name="card_travel" />
          <p v-if="selectedVehicle.vehicle.luggage_capacity">
            {{ $tc('num.bags', selectedVehicle.vehicle.luggage_capacity) }}
          </p>
          <p v-else>
            {{ $t('abbreviation.not_applicable') }}
          </p>
        </div>
      </q-card-section>
    </q-card>

    <q-card class="bg-white" flat>
      <q-card-section class="vehicle-card-info">
        <m-phone-field
          :borderless="$q.platform.is.mobile"
          :dialcode="dialcode"
          :phone="phone"
          :phone-error="$v.info.phone_number.$invalid"
          @dialcode="val => dialcode = val"
          @phone="val => phone = val"
        />
        <q-input
          v-model="info.flight_number"
          :error="$v.info.flight_number.$invalid"
          error-message="Must be a valid IATO or ICAO flight number"
          :label="$t('flight.number')"
          :rounded="$q.platform.is.mobile"
          :outlined="$q.platform.is.mobile"
        />
        <q-input
          v-model="info.instructions"
          type="textarea"
          :label="$t('taxi.message_to_driver')"
          :rounded="$q.platform.is.mobile"
          :outlined="$q.platform.is.mobile"
        />
      </q-card-section>
    </q-card>

    <div class="btn-holder">
      <m-cta-button :label="$t('confirm.vehicle')" @ctaClick="saveInfo" />
    </div>
    <router-view />
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import { required, helpers } from 'vuelidate/lib/validators'
import { MImage, MPhoneField, MCtaButton } from 'components/'
import { priceText as priceTextUtil } from 'utils/onwardTravel'
import handlePhoneNumber from 'utils/phone'

const flightNumberValidator = (value) => {
  if (value.length < 3) return true
  return !helpers.req(value) || /^[A-Z0-9]{2}[0-9]{1,5}$/.test(value)
}

export default {
  components: { MImage, MPhoneField, MCtaButton },
  filters: {
    round (val) {
      return Math.round(val * 10) / 10
    }
  },
  data () {
    return {
      info: {
        instructions: '',
        phone_number: '',
        flight_number: ''
      },
      dialcode: null,
      phone: null,
      loading: false,
      fallback: require('assets/blank-vehicle-hire.png')
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      selectedVehicle: 'ondemand/selected'
    }),
    priceText () {
      return priceTextUtil(this.selectedVehicle)
    }
  },
  watch: {
    dialcode (val) {
      this.info.phone_number = '+' + val + this.phone
    },
    phone (val) {
      this.info.phone_number = '+' + this.dialcode + val
    }
  },
  mounted () {
    const { countryCallingCode, nationalNumber } = handlePhoneNumber(this.user.meta.mobile)
    this.dialcode = countryCallingCode
    this.phone = nationalNumber
  },
  methods: {
    saveInfo () {
      this.$v.$touch()
      if (this.$v.$error) {
        if (this.$v.info.phone_number.$invalid) return this.$q.notify(this.$t('phone_number_required'))
        if (this.$v.info.flight_number.$invalid) return this.$q.notify(this.$t('error.invalid_flight_number'))
      }
      this.$store.dispatch('ondemand/stashProp', {
        path: 'info',
        value: _.pickBy(this.info)
      })
      this.$router.push({ name: 'ondemand-ride-hailing-summary' })
    }
  },
  validations: {
    info: {
      phone_number: { required },
      flight_number: { flightNumberValidator }
    }
  }
}
</script>

<style lang="stylus" scoped>
.vehicle-detail
  font-size 16px
  padding 0 0 12px 0
  line-height 1.1
  display flex
  justify-content space-between
  align-items center

.vehicle-info
  display flex
  justify-content center
  align-items center
  flex-direction column

.btn-holder
  width 100%
  padding 10px
  margin-bottom 15px

.icon
  font-size 26px

.btn-search
  width 100%
  transition width .3s

.description
  font-size 14px
  padding-bottom 12px

.phone-input
  margin-bottom 28px

@keyframes fadeIn {
  0%   { opacity: 0 }
  100% { opacity: 1 }
}

.vehicle-card-title .q-card-section
  display flex
  justify-content space-between

.vehicle > *
  animation: fadeIn .3s forwards
</style>
