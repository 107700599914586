<template>
  <div class="groups shadow-up-2 bg-white">
    <div
      v-if="requestCompletedWithNoResults"
      style="display: grid; width: 100%"
    >
      <q-btn class="back-button" icon="arrow_back" flat round dense @click="$router.back()" />
      <h5 class="empty">
        {{ $t('no_taxis_found') }}
      </h5>
    </div>

    <div v-else class="full-width">
      <q-tabs v-model="tab" align="justify" class="bg-transparent full-width">
        <q-tab
          v-for="v in vehicleGroups"
          :key="v.category_label"
          :name="v.category_label"
          class="taxi-tab"
        >
          <taxi-topper :text="v.category_label" />
        </q-tab>
      </q-tabs>

      <q-tab-panels v-model="tab" class="full-width">
        <q-tab-panel
          v-for="v in vehicleGroups"
          :key="v.category_label"
          :name="v.category_label"
          style="padding: 0"
        >
          <vehicle-type-list :key="v.name" :vehicle-group="v" />
        </q-tab-panel>
      </q-tab-panels>
    </div>
  </div>
</template>

<script>
import vehicleTypeList from './vehicle-type-list'
import taxiTopper from './taxi-topper.vue'
import store from 'store'
import i18n from 'i18n'
import loading from 'utils/loading'
import { handleErrors } from 'utils/utils'
import { search } from 'api/ride-hailing'
import getPartnerFromHostname from 'utils/partner'

export default {
  components: { vehicleTypeList, taxiTopper },
  data () {
    return {
      slide: null,
      tab: 'Taxi',
      vehicleGroups: []
    }
  },
  computed: {
    requestCompletedWithNoResults () {
      return !this.vehicleGroups.length
    }
  },
  async beforeRouteEnter (to, from, next) {
    const partner = store.getters.partner

    loading.start({
      message: i18n.t('loading.searching.taxi'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const partner = getPartnerFromHostname().slug
      const params = store.getters['ondemand/stash'].parameters
      const { traveller, requester, origin, destination, time, depart, passengers } = params
      const res = await search({
        origin: origin.udprn || origin.place_id || origin.value,
        destination: destination.udprn || destination.place_id || destination.value,
        traveller: traveller.value,
        requester: requester.value,
        time: time || depart,
        wheelchair_accessible: store.getters['ondemand/wheelchairRequired'],
        passengers,
        ...(partner === 'gohi' && { included_providers: ['C1'] })
      })

      let sortedGroups = []

      res.data.forEach(item => {
        if (item.category_label === 'Taxi') sortedGroups[0] = item
        else if (item.category_label === 'Exec') sortedGroups[1] = item
        else if (item.category_label === 'MPV') sortedGroups[2] = item
        else if (item.category_label === 'WAV') sortedGroups[3] = item
        else if (item.category_label === 'Other') sortedGroups[4] = item
        else sortedGroups.push(item)
      })

      sortedGroups = sortedGroups.filter(item => item !== null) // Remove empty indexes resulting from that category not returning

      sortedGroups.forEach(group => {
        group.options.sort((option1, option2) => option1.price.amount - option2.price.amount)
      })

      next((vm) => {
        vm.vehicleGroups = sortedGroups
        vm.tab = sortedGroups[0]?.category_label || 'Taxi'
        loading.stop()
      })
    } catch (err) {
      console.log(err)
      handleErrors(err)
      next(false)
      loading.stop()
    }
  }
}
</script>

<style lang="stylus" scoped>
.groups
  background white
  width 100%
  display flex
  position: absolute
  top: 0
  bottom: 0

.empty
  width 70%
  font-weight 300
  margin auto
  text-align center

.back-button
  position absolute
  margin 15px

</style>

<style lang="stylus">
.groups .q-carousel-left-arrow, .groups .q-carousel-right-arrow
  background white
  top 25px

.taxi-tab
  width 20vw
  padding 0
</style>
