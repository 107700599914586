<template>
  <map-form
    :show-passengers="passengers"
    :show-transport-prefs="false"
    :show-wheelchair="true"
    @submit="submit"
  />
</template>

<script>
import MapForm from '../map-form/form'
import { mapGetters } from 'vuex'

export default {
  components: { MapForm },
  computed: {
    ...mapGetters({
      origin: 'ondemand/origin',
      destination: 'ondemand/destination',
      time: 'ondemand/time',
      passengers: 'ondemand/passengers',
      wheelchairRequired: 'ondemand/wheelchairRequired'
    })
  },
  activated () {
    this.$store.dispatch('ondemand/stashProp', { path: 'passengers', value: { passengers: 1 } })
  },
  mounted () {
    this.$store.dispatch('ondemand/stashProp', { path: 'passengers', value: { passengers: 1 } })
  },
  methods: {
    submit () {
      const parameters = {
        origin: this.origin,
        destination: this.destination,
        time: this.time,
        passengers: this.passengers.passengers,
        wheelchair_accessible: this.wheelchairRequired
      }

      this.$store.dispatch('ondemand/parameters', parameters)
      this.$router.push({ name: 'ondemand-ride-hailing-results' })
    }
  }
}
</script>
