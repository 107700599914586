<template>
  <q-page padding>
    <div v-if="summary" class="row q-col-gutter-xs">
      <div class="col-xs-12">
        <q-card class="bg-white full-width">
          <q-card-section class="bg-primary text-white">
            {{ $t('booking.summary') }}
          </q-card-section>
          <q-card-section>
            <div class="row q-mt-md">
              <div class="col-6 column">
                <div class="col">
                  <sub>{{ $tc('vehicle') }}</sub>
                  <h6 class="text-grey-9">
                    {{ summary.ride.vehicle.type }}
                    <br>
                    {{ summary.ride.title }}
                  </h6>
                </div>
                <div class="col">
                  <sub>{{ $t('description') }}</sub>
                  <h6 class="text-grey-9">
                    {{ summary.ride.description }}
                  </h6>
                </div>
              </div>
              <div class="col-6">
                <m-image :src="summary.ride.vehicle.images[0]" :fallback="fallback" width="142" height="88" />
              </div>
              <div class="col-12 col-sm-6">
                <sub>{{ $t('taxi.company') }}</sub>
                <h6 class="text-grey-9">
                  {{ summary.ride.taxi_company || summary.ride.provider }}
                </h6>
              </div>
              <div class="col-12 col-sm-6">
                <sub>{{ $t('taxi.provider') }}</sub>
                <h6 class="text-grey-9">
                  {{ summary.ride.provider }}
                </h6>
              </div>
              <div class="col-12 col-sm-6">
                <sub>{{ $t('pick_up') }}</sub>
                <h6 class="text-grey-9">
                  {{ summary.pickup_time.date | date }}
                </h6>
                {{ summary.origin }}
              </div>
              <div class="col-12 col-sm-6">
                <sub>{{ $t('drop_off') }}</sub>
                <h6 class="text-grey-9">
                  -
                </h6>
                {{ summary.destination }}
              </div>
            </div>

            <q-separator class="hr" />
            <h6 class="q-mt-md">
              {{ $t('other_travel_information') }}
            </h6>
            <div class="grid">
              <q-item v-if="summary.phone_number">
                <q-item-label>
                  <q-item-label label>
                    {{ $t('phone_number') }}
                  </q-item-label>
                  <q-item-label caption>
                    {{ summary.phone_number }}
                  </q-item-label>
                </q-item-label>
              </q-item>
              <q-item v-if="summary.flight_number">
                <q-item-label>
                  <q-item-label label>
                    {{ $t('flight.number') }}
                  </q-item-label>
                  <q-item-label caption>
                    {{ summary.flight_number }}
                  </q-item-label>
                </q-item-label>
              </q-item>
              <q-item v-if="summary.instructions">
                <q-item-label>
                  <q-item-label label>
                    {{ $t('instructions') }}
                  </q-item-label>
                  <q-item-label caption>
                    {{ summary.instructions }}
                  </q-item-label>
                </q-item-label>
              </q-item>
            </div>
            <q-separator class="hr" />
            <div v-for="(item, i) in summary.order_items" :key="i" class="order-item">
              <div class="description">
                {{ item.description }}
              </div>
              <div class="grid">
                <q-item>
                  <q-item-section>
                    <q-item-label label>
                      {{ $t('net_amount') }}
                    </q-item-label>
                    <q-item-label caption>
                      {{ item.net_amount.text }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label label>
                      {{ $t('tax.amount') }}
                    </q-item-label>
                    <q-item-label caption>
                      {{ item.tax_amount.text }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
                <q-item>
                  <q-item-section>
                    <q-item-label label>
                      {{ $t('total_cost') }}
                    </q-item-label>
                    <q-item-label caption>
                      {{ item.total.text }}
                    </q-item-label>
                  </q-item-section>
                </q-item>
              </div>
            </div>

            <q-separator class="hr" />
            <h6 class="row text-bold">
              <span class="offset-5 col-4 text-grey-9 text-right">{{ $t('total_cost') }}</span>
              <span class="col-3 text-grey-9 text-right">{{ summary.total_amount.text }}</span>
            </h6>
          </q-card-section>
        </q-card>
      </div>
      <div class="col-sm-12">
        <m-banner
          :title="$t('information')"
          :message="$t('taxi.final_fare_calculation')"
          color="primary"
        />
      </div>
      <div class="col-xs-12">
        <m-attach-to-booking-request
          :suggested="summary.suggested_name"
          :username="stash.parameters.traveller.value"
          :journey="journey"
          :show-travel-type="travelPolicy && travelPolicy.ridehailing.enabled === true"
          @change="(val) => $store.dispatch('ondemand/journey', val)"
          @reasonChange="(val) => reason_for = val"
          @labelChange="(val) => labels = val"
          @travelTypeChange="(val) => travelType = val"
        />
      </div>
      <payment-buttons
        :total-amount="summary.total_amount.amount"
        :requester="stash.parameters.requester"
        :traveller="stash.parameters.traveller || stash.parameters.requester"
        :journey="journey"
        :reason-for="reason_for"
        :labels="labels"
        :booking-token="summary.booking_token"
        content-type="ondemand-ride-hailing"
        :travel-type="(travelPolicy && travelPolicy.ridehailing.enabled) || !organisation ? travelType : 'Private'"
      />
      <m-terms />
    </div>
  </q-page>
</template>

<script type="text/javascript">
import { mapGetters } from 'vuex'
import authentication from 'mixins/authentication'
import date from 'utils/date-time'
import paymentButtons from 'pages/payments/payment-method-buttons'
import loading from 'utils/loading'
import store from 'store'
import i18n from 'i18n'
import { handleErrors } from 'utils/utils'
import { MImage, MBanner, MAttachToBookingRequest, MTerms } from 'components/'
import { additionalInfo } from 'api/ride-hailing'

export default {
  name: 'OndemandRideHailingSummary',
  components: {
    paymentButtons,
    MImage,
    MBanner,
    MAttachToBookingRequest,
    MTerms
  },
  filters: {
    date: value => date.toMediumDateTime(value)
  },
  mixins: [authentication],
  data () {
    return {
      reason_for: null,
      labels: null,
      fallback: require('assets/blank-vehicle-hire.png'),
      travelType: null,
      summary: null
    }
  },
  computed: {
    ...mapGetters({
      journey: 'ondemand/journey',
      partner: 'partner',
      organisation: 'organisation',
      stash: 'ondemand/stash'
    }),
    travelPolicy () {
      if (!this.organisation) return null
      if (this.organisation?.attributes?.travel_policy) {
        return this.organisation.attributes.travel_policy
      }
      return { ridehailing: { enabled: true } }
    }
  },
  async beforeRouteEnter (to, from, next) {
    const partner = store.getters.partner
    const info = store.getters['ondemand/info']
    const selectedVehicle = store.getters['ondemand/selected']

    loading.start({
      message: i18n.t('loading.loading'),
      partnerSlug: partner.slug,
      spinnerColor: partner.hex
    })

    try {
      const res = await additionalInfo(selectedVehicle.token, info)

      next((vm) => {
        vm.summary = res.data
        loading.stop()
      })
    } catch (err) {
      handleErrors(err)
      next(from)
      loading.stop()
    }
    next()
  }
}
</script>

<style lang="stylus" scoped>
span, .hr
  margin-top 16px
button
  width 100%
.summary-main
  padding 20px

.grid
  display grid
  grid-template-columns repeat(auto-fill, 33%);
  font-weight 400
  align-items baseline

.order-item
  margin-top 16px

.grid > div
  padding 10px 0

.grid > div:nth-child(2)
  text-align center

.grid > div:nth-child(3)
  text-align right

.description
  font-weight 700

h6.text-bold
  padding 0 10px
</style>
