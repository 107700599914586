<template>
  <div class="topper">
    <svg xmlns="http://www.w3.org/2000/svg" width="57.6" height="26.3">
      <g :fill="topper[text].backgroundColor" data-name="Group 283">
        <path data-name="Path 1066" d="M24.6 13.9h2.7L26 10.2z" />
        <path data-name="Path 1067" d="M57.4 21.3L52.2 2.4A3.3 3.3 0 0 0 49 0H8.6a3.3 3.3 0 0 0-3.2 2.4l-5.3 19a3.3 3.3 0 0 0 3.2 4.3h51a3.3 3.3 0 0 0 3.1-4.4zm-39.1-12v8.2a.8.8 0 0 1-.8.8h-.8c-.4 0 2 2.8 2 2.3l31-.9-36-13.3c-.5 0-1 4.8-1 4.4v.5c0-.4 2 7 2.5 7l5.5-11a.8.8 0 0 1 .8.8v.5a22.8 22.8 0 0 0 2.3-2.7c-.7 0-6 2-6.3 2zM31 18a.8.8 0 0 1-.6.4h-.9a.8.8 0 0 1-.7-.5l-.7-2h-4.3l-.7 2a.8.8 0 0 1-.7.5h-.8a.8.8 0 0 1-.7-1l3.7-9.4c0-.3 11.6 9.2 12 9.2l-10-9.7a.8.8 0 0 1 .7.5l3.8 9.4a.8.8 0 0 1 0 .6zm9.9 0a.8.8 0 0 1-.7.4h-1a.8.8 0 0 1-.6-.4l-2.1-3.3-2.2 3.3a.8.8 0 0 1-.6.4h-1a.8.8 0 0 1-.6-1.2l3-4.5-2.7-4a.8.8 0 0 1 .7-1.2h.9c.2 0-3.1 5.7-3 5.9l5.5-2.5 1.9-3a.8.8 0 0 1 .6-.4h.9c.3 0-5.7 5-5.6 5.3s2.4-2.2 2.2-2l1.4 2 3 4.4a.8.8 0 0 1 0 .8zm3.6.4c0 .4 0 0-.5 0h-.8a.8.8 0 0 1-.7-.8V8.1a.8.8 0 0 1 .7-.7l-20-3.6c.4 0-2.5 12.4-2.5 12.9z" />
      </g>
    </svg>
    <div :style="`color: ${topper[text].textColor}`" class="text">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: ['text'],
  data () {
    return {
      topper: {
        'Taxi': {
          backgroundColor: '#ffd500',
          textColor: 'black'
        },
        'MPV': {
          backgroundColor: '#3face1',
          textColor: 'white'
        },
        'Exec': {
          backgroundColor: 'black',
          textColor: 'white'
        },
        'Other': {
          backgroundColor: '#ff7526',
          textColor: 'white'
        },
        'WAV': {
          backgroundColor: '#82C09A',
          textColor: 'black'
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped>
.text
  font-size 13px
  position absolute
  width 100%
  top 8px
  left 0
  text-align center
</style>
