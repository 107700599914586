<template>
  <div class="map-form">
    <div class="choose-route">
      <q-icon
        v-if="originField && originField.context"
        name="img:/w3w-slashes.png"
        size="32px"
      />
      <q-icon v-else name="fas fa-location" color="primary" size="24px" />
      <m-location-search
        ref="originField"
        clearable
        :placeholder="$t('input.starting_location')"
        :value="originField"
        :show-icon="false"
        :input-style="{ 'font-size': '110%' }"
        style="margin-bottom: -4px; margin-top: 4px"
        @selected="(val) => (originField = val)"
        @open="() => (tall = true)"
        @close="() => (tall = false)"
      />
      <button class="swap" @click="swapLocations">
        <q-icon name="fas fa-sort-alt" size="24px" />
      </button>
      <q-icon
        v-if="destinationField && destinationField.context"
        name="img:/w3w-slashes.png"
        size="32px"
      />
      <q-icon v-else name="fas fa-map-marker" color="primary" size="24px" />
      <m-location-search
        ref="destinationField"
        clearable
        :placeholder="$t('input.destination')"
        :value="destinationField"
        :show-icon="false"
        :input-style="{ 'font-size': '110%' }"
        style="margin-bottom: -4px; margin-top: 4px"
        @selected="(val) => (destinationField = val)"
        @open="() => (tall = true)"
        @close="() => (tall = false)"
      />
    </div>
    <m-leave-now
      :departing="time"
      :show-transport-prefs="showTransportPrefs"
      :show-time="showTime"
      :show-passengers="showPassengers"
      :show-wheelchair="showWheelchair"
      :disabled="disabled"
      :date-options="dateOptions"
      :time-options="timeOptions"
      :railcard-options="concessionOptions"
      :wheelchair-required="wheelchairRequired"
      @passengers="
        (passengers) =>
          $store.dispatch('ondemand/stashProp', {
            path: 'passengers',
            value: passengers,
          })
      "
      @concessions="
        (cards) =>
          $store.dispatch('ondemand/stashProp', { path: 'cards', value: cards })
      "
      @depart="
        (time) =>
          $store.dispatch('ondemand/stashProp', { path: 'time', value: time })
      "
      @wheelchair="
        (wheelchair) =>
          $store.dispatch('ondemand/stashProp', {
            path: 'wheelchairRequired',
            value: wheelchair,
          })
      "
      @submit="$emit('submit')"
    >
      <slot id="info" />
    </m-leave-now>
  </div>
</template>

<script>
import { MLeaveNow } from 'components/'
// Need to load this in manually so we can access $refs right away
import MLocationSearch from 'components/MLocationSearch'
import date from 'utils/date-time'
import { mapGetters } from 'vuex'
const { addToDate, toCivilDateTime } = date

export default {
  name: 'OnDemandMapForm',
  components: { MLocationSearch, MLeaveNow },
  props: [
    'showPassengers',
    'showTime',
    'showTransportPrefs',
    'showWheelchair',
    'concessionOptions',
    'dateOptions',
    'timeOptions'
  ],
  data () {
    return {
      tall: false
    }
  },
  computed: {
    ...mapGetters({
      latLng: 'geolocate/latLng',
      origin: 'ondemand/origin',
      destination: 'ondemand/destination',
      time: 'ondemand/time',
      passengers: 'ondemand/passengers',
      selectionMode: 'ondemand/selectionMode',
      stash: 'ondemand/stash',
      userLookup: 'userLookup',
      wheelchairRequired: 'ondemand/wheelchairRequired'
    }),
    originField: {
      get () {
        return this.$store.getters['ondemand/originField']
      },
      set (val) {
        this.$store.dispatch('ondemand/setLocation', { field: 'origin', location: val })
      }
    },
    destinationField: {
      get () {
        return this.$store.getters['ondemand/destinationField']
      },
      set (val) {
        this.$store.dispatch('ondemand/setLocation', { field: 'destination', location: val })
      }
    },
    disabled () {
      return !this.origin || !this.destination
    }
  },
  watch: {
    tall (val) {
      const inset = getComputedStyle(document.documentElement).getPropertyValue('--inset-bottom')
      const sheet = document.getElementById('map-form')
      const { height } = sheet.getBoundingClientRect()
      const innerHeight = sheet.querySelector('.map-form').getBoundingClientRect().height
      const numInset = Number(inset.slice(0, -2))
      const bottom = innerHeight - 55 + numInset

      if (val) {
        sheet.parentElement.parentElement.parentElement.scroll({ top: height, behavior: 'smooth' })
      } else {
        sheet.parentElement.parentElement.parentElement.scrollTo({ top: (bottom), behavior: 'smooth' })
      }
    }
  },
  activated () {
    // Default "Now" to 15 minutes from now
    this.$store.dispatch('ondemand/stashProp', { path: 'time', value: toCivilDateTime(addToDate(date.newDate(), { minutes: 15 })) })
    // Set traveller/requester to current user
    this.$store.dispatch('ondemand/parameters', { traveller: this.userLookup, requester: this.userLookup })
  },
  mounted () {
    // Default "Now" to 15 minutes from now
    this.$store.dispatch('ondemand/stashProp', { path: 'time', value: toCivilDateTime(addToDate(date.newDate(), { minutes: 15 })) })
    // Set traveller/requester to current user
    this.$store.dispatch('ondemand/parameters', { traveller: this.userLookup, requester: this.userLookup })
  },
  methods: {
    swapLocations () {
      const oldOrigin = this.originField
      const oldDestination = this.destinationField

      this.destinationField = oldOrigin
      this.originField = oldDestination
    }
  }
}
</script>

<style lang="stylus" scoped>
.map-form
  right 35%
  width 30vw
  padding 10px 10px 0
  height fit-content

  @media (max-width 768px)
    width 100vw
    right 0
    left 0
    margin 0

.q-field--with-bottom
  padding-bottom 0

.choose-route
  display grid
  grid-template-columns 36px auto 30px
  grid-column-gap 6px
  grid-template-rows auto
  align-items center

.swap
  grid-column-start 3
  grid-row-start 1
  grid-row-end 3
  color var(--q-color-primary)
  background white
  border none

.drt-button
  background white
  padding 6px
  border none
  display flex
  flex-direction column
  justify-content center
  align-items center

.confirm
  background var(--q-color-primary)
  color white
  font-size 20px
  width 100%
  grid-column-start 1
  grid-column-end 4
  border-radius 3px
  margin-bottom 24px
  line-height 1.715em
  padding 4px
  font-weight 500
  height 50px
</style>
